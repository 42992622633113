<template>
  <section>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:visible', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">
            Editar Fornecedor
          </h5>

          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <b-form
          class="p-2"
          @submit.prevent="handleSubmit()"
          @reset.prevent="resetForm"
        >

          <b-form-group
            label="Nome"
            label-for="name"
          >
            <b-form-input
              id="service-name"
              v-model="item.name"
              autofocus
              trim
              placeholder="Nome do Fornecedor"
            />
          </b-form-group>

          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="service-email"
              v-model="item.email"
              autofocus
              trim
              placeholder="Email do Fornecedor"
            />
          </b-form-group>

          <b-form-group
            label="Pix"
            label-for="pix"
          >
            <b-form-input
              id="service-pix"
              v-model="item.pix"
              autofocus
              trim
              placeholder="Pix do Fornecedor"
            />
          </b-form-group>

          <b-form-group
            label="Nome"
            label-for="name"
          >
            <b-form-input
              id="service-name"
              v-model="item.name"
              autofocus
              trim
              placeholder="Nome do Fornecedor"
            />
          </b-form-group>

          <b-form-group
            label="Número de Contato"
            label-for="phone_number"
          >
            <b-form-input
              id="phone_number"
              v-model="item.phone_number"
              v-mask="'(##) #####-####'"
              placeholder="(11) 95555-5555"
            />
          </b-form-group>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Limpar
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </section>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mask } from 'vue-the-mask'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton
  },
  directives: {
    Ripple,
    mask
  },
  model: {
    prop: 'visible',
    event: 'update:visible'
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    clearForm () {
      //
    },
    async handleSubmit () {
      const url = `/admin/suppliers/${this.item.id}`
      const params = {
        name: this.item.name,
        email: this.item.email,
        pix: this.item.pix,
        phone_number: this.item.phone_number
      }

      this.$http.patch(url, params).finally(() => {
        this.$emit('updated', this.item)
        this.$emit('update:visible', false)

        this.$swal({
          title: 'Atualizado com sucesso!!',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1'
          },
          buttonsStyling: false
        })
      })
    }
  }
}
</script>
